import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import testsData from './testsData.json';

const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(2000 + parseInt(year), parseInt(month) - 1, parseInt(day));
};

export default function Navbar({ isDarkMode, toggleDarkMode }) {
    const [upcomingTest, setUpcomingTest] = useState(null);

    useEffect(() => {
        const now = new Date();
        const oneWeekFromNow = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);

        const nextTest = testsData.find(test => {
            const testDate = parseDate(test.date);
            return testDate > now && testDate <= oneWeekFromNow;
        });

        setUpcomingTest(nextTest);
    }, []);

    return (
        <div>
            {upcomingTest && (
                <div className="upcoming-test-alert">
                    Предстоящо контролно: {upcomingTest.subject} на {upcomingTest.date}
                </div>
            )}
            <nav className="navbar">
                <ul className="navbar-links">
                    <li><Link to="/">Текуща</Link></li>
                    <li><Link to="/all-lectures">Всички</Link></li>
                    <li>
                        <Link to="/tests">
                            <img src="/assets/icons/test-icon.svg" alt="Контролно" width="24" height="24" />
                            {/*Контролни*/}
                        </Link>
                    </li>
                    <li>
                        <Link to="/points">
                            <img src="/assets/icons/star-icon.svg" alt="Точки" width="24" height="24" />
                            {/*Точки*/}
                        </Link>
                    </li>
                    <li>
                        <button onClick={toggleDarkMode} className="toggle-dark-mode">
                            {isDarkMode ? (
                                <img src="/assets/icons/sun-icon.svg" alt="Икона на слънце" width="24" height="24" />
                            ) : (
                                <img src="/assets/icons/moon-icon.svg" alt="Икона на луна" width="24" height="24" />
                            )}
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
