import React, { useState, useEffect } from 'react';
import testsData from './testsData.json';

const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(2000 + parseInt(year), parseInt(month) - 1, parseInt(day));
};

const formatDate = (dateString) => {
    const date = parseDate(dateString);
    return date.toLocaleDateString('bg-BG', {
        day: 'numeric',
        month: 'long',
    });
};

const getDayOfWeek = (dateString) => {
    const date = parseDate(dateString);
    const days = ['Неделя', 'Понеделник', 'Вторник', 'Сряда', 'Четвъртък', 'Петък', 'Събота'];
    return days[date.getDay()];
};

export default function Tests() {
    const [tests, setTests] = useState([]);

    useEffect(() => {
        const sortedTests = [...testsData].sort((a, b) => parseDate(a.date) - parseDate(b.date));
        setTests(sortedTests);
    }, []);

    const getTestStatus = (date) => {
        const now = new Date();
        const testDate = parseDate(date);
        const oneWeekFromNow = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);

        if (testDate < now) {
            return 'status-red';
        } else if (testDate <= oneWeekFromNow) {
            return 'status-orange';
        } else {
            return 'status-green';
        }
    };

    return (
        <div className="tests-container">
            <h1>Контролни</h1>
            <div className="tests-grid">
                {tests.map((test, index) => (
                    <div key={index} className={`test-card ${getTestStatus(test.date)}`}>
                        <h2>{test.subject} <span className="day">{getDayOfWeek(test.date)}</span></h2>
                        <div className="lecture-details">
                            <p><strong>Дата:</strong> {formatDate(test.date)}</p>
                            <p><strong>Точки:</strong> {test.points}</p>
                            <p><strong>Описание:</strong> {test.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}