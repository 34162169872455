import React, { useState, useEffect } from 'react';
import scheduleData from './scheduleData.json';

function parseTime(timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
}

function getCurrentLecture(lectures) {
    const now = new Date();
    const currentDay = ['Неделя', 'Понеделник', 'Вторник', 'Сряда', 'Четвъртък', 'Петък', 'Събота'][now.getDay()];
    const currentTime = now.getHours() * 60 + now.getMinutes();

    return lectures.find(lecture => {
        if (lecture.day !== currentDay) return false;
        const [start, end] = lecture.hour.split('-').map(parseTime);
        return currentTime >= start && currentTime < end;
    });
}

function getNextLecture(lectures, currentLecture) {
    const currentIndex = lectures.indexOf(currentLecture);
    const sameDayLectures = lectures.filter(lecture => lecture.day === currentLecture.day);
    const nextSameDayLecture = sameDayLectures.find((lecture, index) =>
        index > sameDayLectures.indexOf(currentLecture)
    );
    return nextSameDayLecture || null;
}

export default function CurrentLecture({ isDarkMode }) {
    const [currentLecture, setCurrentLecture] = useState(null);
    const [nextLecture, setNextLecture] = useState(null);
    const [progress, setProgress] = useState(0);
    const [timeLeft, setTimeLeft] = useState('');
    const [status, setStatus] = useState('default');
    const [currentDay, setCurrentDay] = useState('');

    useEffect(() => {
        const updateLecture = () => {
            const now = new Date();
            const lectures = scheduleData.lectures;
            const lecture = getCurrentLecture(lectures);
            const currentDayName = ['Неделя', 'Понеделник', 'Вторник', 'Сряда', 'Четвъртък', 'Петък', 'Събота'][now.getDay()];
            setCurrentDay(currentDayName);

            setCurrentLecture(lecture);

            if (lecture) {
                const nextLec = getNextLecture(lectures, lecture);
                setNextLecture(nextLec);

                const [start, end] = lecture.hour.split('-').map(parseTime);
                const currentTime = now.getHours() * 60 + now.getMinutes();
                const totalDuration = end - start;
                const elapsed = currentTime - start;
                const newProgress = Math.min(100, (elapsed / totalDuration) * 100);
                setProgress(newProgress);

                const minutesLeft = end - currentTime;
                const secondsLeft = 60 - (now.getSeconds() % 60);

                if (minutesLeft > 1) {
                    setTimeLeft(`${minutesLeft} мин`);
                } else if (minutesLeft === 1) {
                    setTimeLeft(`${secondsLeft} сек`);
                } else {
                    setTimeLeft(`${secondsLeft} сек`);
                }

                if (minutesLeft <= 10) {
                    setStatus('orange');
                } else {
                    setStatus('green');
                }
            } else {
                const currentTime = now.getHours() * 60 + now.getMinutes();
                const currentDay = ['Неделя', 'Понеделник', 'Вторник', 'Сряда', 'Четвъртък', 'Петък', 'Събота'][now.getDay()];

                const nextLecture = lectures.find(l => {
                    const [start] = l.hour.split('-').map(parseTime);
                    return l.day === currentDay && start > currentTime;
                });

                if (nextLecture) {
                    setStatus('orange');
                    setNextLecture(nextLecture);
                } else {
                    setStatus('default');
                    setNextLecture(null);
                }
                setProgress(0);
                setTimeLeft('');
            }
        };

        updateLecture();
        const interval = setInterval(updateLecture, 1000); // Update every second

        return () => clearInterval(interval);
    }, []);

    if (!currentLecture && !nextLecture) {
        return (
            <div>
                <div className={`day-box ${isDarkMode ? 'dark-mode' : ''}`}>
                    <h3 className="gradient-text">{currentDay}</h3>
                </div>
                <div className="current-lecture">Няма планирани лекции за днес</div>
            </div>
        );
    }

    return (
        <div>
            <div className={`day-box ${isDarkMode ? 'dark-mode' : ''}`}>
                <h3 className="gradient-text">{currentDay}</h3>
            </div>
            <div className={`current-lecture ${status}`}>
                {currentLecture && (
                    <>
                        <h2><span className="lecture-type">[{currentLecture.type}]</span> {currentLecture.lecture}</h2>
                        <div className="lecture-details">
                            <p>Преподавател: {currentLecture.lecturer}</p>
                            <p>Час: {currentLecture.hour}</p>
                            <p>Стая: {currentLecture.roomNo}</p>
                        </div>
                        <div className="progress-info">
                            <span>Оставащо време: {timeLeft}</span>
                            <span>{Math.round(progress)}%</span>
                        </div>
                        <div className="progress-bar-container">
                            <div className="progress-bar" style={{ width: `${progress}%` }}></div>
                        </div>
                    </>
                )}
                {(status === 'orange' || !currentLecture) && nextLecture && (
                    <div className="next-lecture">
                        <h3>Следваща лекция</h3>
                        <p><strong><span className="lecture-type">[{nextLecture.type}]</span> {nextLecture.lecture}</strong></p>
                        <p>Час: {nextLecture.hour}</p>
                        <p>Стая: {nextLecture.roomNo}</p>
                    </div>
                )}
            </div>
        </div>
    );
}