import React, { useState, useEffect } from 'react';
import pointsData from './pointsData.json';

const PointsTracker = () => {
    const [students, setStudents] = useState([]);
    const [accessCode, setAccessCode] = useState('');
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState('Всички');

    useEffect(() => {
        setStudents(pointsData);
    }, []);

    const handleAccessCode = (e) => {
        e.preventDefault();
        if (accessCode === '2024') {
            setIsAuthorized(true);
        } else {
            alert('Невалиден код за достъп');
        }
    };

    const handleStudentChange = (e) => {
        setSelectedStudent(e.target.value);
    };

    const calculateTotalPoints = (breakdown) => {
        return breakdown.reduce((total, item) => total + item.points, 0);
    };

    const renderProgressBar = (breakdown, maxPoints) => {
        const totalPoints = calculateTotalPoints(breakdown);
        const colors = ['#f44336', '#2196f3', '#4caf50', '#ff9800', '#9c27b0'];

        return (
            <div className="pt-progress-bar-container">
                <div className="pt-progress-bar">
                    {breakdown.map((item, index) => (
                        <div
                            key={index}
                            className="pt-progress-section"
                            style={{
                                width: `${(item.points / maxPoints) * 100}%`,
                                backgroundColor: colors[index % colors.length],
                            }}
                        >
                            {item.points}
                        </div>
                    ))}
                </div>
                <span className="pt-total-points">{totalPoints}/{maxPoints}</span>
            </div>
        );
    };

    const renderPointsBreakdown = (breakdown) => {
        const colors = ['#f44336', '#2196f3', '#4caf50', '#ff9800', '#9c27b0'];

        return (
            <div className="pt-points-breakdown">
                {breakdown.map((item, index) => (
                    <div key={index} className="pt-points-breakdown-item">
                        <div
                            className="pt-points-breakdown-color"
                            style={{ backgroundColor: colors[index % colors.length] }}
                        ></div>
                        {item.type}: {item.points}
                    </div>
                ))}
            </div>
        );
    };

    const filteredStudents = selectedStudent === 'Всички'
        ? students
        : students.filter((student) => student.name === selectedStudent);

    if (!isAuthorized) {
        return (
            <div className="pt-access-code-form">
                <h2>Въведете код за достъп</h2>
                <form onSubmit={handleAccessCode}>
                    <input
                        type="password"
                        value={accessCode}
                        onChange={(e) => setAccessCode(e.target.value)}
                        placeholder="Код за достъп"
                    />
                    <button type="submit">Вход</button>
                </form>
            </div>
        );
    }

    return (
        <div className="pt-points-tracker">
            <h1>Проследяване на точки</h1>

            {/* Dropdown за избор на студент */}
            <div className="pt-student-filter">
                <label htmlFor="student-select">Избери студент:</label>
                <select id="student-select" value={selectedStudent} onChange={handleStudentChange}>
                    <option value="Всички">Всички</option>
                    {students.map((student) => (
                        <option key={student.name} value={student.name}>
                            {student.name}
                        </option>
                    ))}
                </select>
            </div>

            {/* Показване на филтрираните студенти */}
            {filteredStudents.map((student) => (
                <div key={student.name} className="pt-student-card">
                    <h2>{student.name}</h2>
                    {student.subjects.map((subject) => (
                        <div key={subject.name} className="pt-subject-progress">
                            <h3>
                                {subject.name}
                                {subject.isExam && <span className="pt-exam-tag">Изпит</span>}
                            </h3>
                            {renderProgressBar(subject.breakdown, subject.maxPoints)}
                            {renderPointsBreakdown(subject.breakdown)}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default PointsTracker;
